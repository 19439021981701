<!--  -->
<template>
    <div class="about">
        <div class="effect-1"></div>

        <div class="about-us">
            <!-- <svg-icon icon-class="image_5" class="about-img" /> -->
            <!-- <svg-icon icon-class="image_6" class="about-img2" /> -->
            <img class="about-img" src="@/assets/images/image5.png" alt="">
            <img class="about-img2" src="@/assets/images/image6.png" alt="">
            <div class="us-text">
                <div class="title">
                    <svg-icon icon-class="about" class="svg-img title-img1" />
                    <svg-icon icon-class="about_2" class="svg-img title-img2" />
                    <!-- <img class="title-img1" src="@/assets/images/about.png" alt="">
                    <img class="title-img2" src="@/assets/images/about_2.png" alt=""> -->
                </div>
                <svg-icon icon-class="arrow_0" class="arrow-img" />
                <!-- <img class="arrow-img" src="@/assets/images/arrow_0.png" alt=""> -->
                <div class="text">
                    MaskMan is a digital art project composed of 10,000 vivid and interesting PFP
                </div>
                <p>
                    <svg-icon icon-class="sun" class="svg-img" />
                    Our artists use over 300 unique elements to create these beautiful and entertaining digital artwork.
                </p>
                <p>
                    <svg-icon icon-class="sun" class="svg-img" />
                    We are dedicated to combining art, commerce, and digital assets together providing a brand new way for
                    investors, art enthusiasts, and gamers to own and enjoy art.
                </p>
                <p>
                    <svg-icon icon-class="sun" class="svg-img" />
                    By participating in MaskMan, you will have the opportunity to own a one-of-a-kind digital art price and
                    explore a creative and passionate crypto world
                </p>
                <div class="badge">
                    <!-- <img src="@/assets/icons/badge.png" alt=""> -->
                    <svg-icon icon-class="badge" class="svg-badge" />

                </div>
            </div>
        </div>
        <div class="team">
            <div class="title">
                <svg-icon icon-class="three" class="left" />
                <svg-icon icon-class="team" class="team-img" />
                <!-- <img class="team-img" src="@/assets/images/Team.png" alt=""> -->
                <svg-icon icon-class="three" class="right" />
            </div>
            <p class="text-1">
                The diverse backgrounds and shared passion of the founding members inject infinite vitality and creativity
                into the success of the MaskMan project.
            </p>
            <div class="introduction">
                <div class="weik">
                    <img src="@/assets/images/Weik.png" alt="">
                    <!-- <svg-icon icon-class="weik" class="svg-img" /> -->
                    <div class="effect-2"></div>
                    <div class="name">Locke</div>
                    <div class="acter">A dremar who wants to become Superman</div>
                </div>
                <div class="jhon">
                    <img src="@/assets/images/Jhon.png" alt="">
                    <!-- <svg-icon icon-class="jhon" class="svg-img" /> -->
                    <div class="effect-3"></div>
                    <div class="name">Jhon</div>
                    <div class="acter">A coding master who loves outdoor adventures</div>
                </div>
                <div class="peter">
                    <img src="@/assets/images/Peter.png" alt="">
                    <!-- <svg-icon icon-class="peter" class="svg-img" /> -->
                    <div class="effect-4"></div>
                    <div class="name">Peter</div>
                    <div class="acter">A painting artist who wants to connect Procreat to her brain</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>
<style scoped lang="scss">
.about {
    width: 1168px;
    margin-left: auto;
    margin-right: auto;

    .about-us {
        display: flex;
        align-items: flex-start;
        // padding: 84.03px 203.19px 0 171.67px;
        padding-top: 84.03px;
        position: relative;
        z-index: 33;

        .about-img {
            margin-top: 44.6px;
            width: 525.73px;
            height: 569.87px;
            z-index: 2;
            flex-shrink: 0;
        }

        .about-img2 {
            display: none;
        }

        .us-text {
            margin-left: 63.27px;

            .title {
                position: relative;
                left: -200px;
                height: 110px;

                .svg-img {
                    width: 505px;
                    position: absolute;
                    left: 0;
                    top: -24px;
                    height: auto;
                    padding: 12px 0;
                }

                .title-img1 {
                    z-index: 2;
                }

                .title-img2 {
                    z-index: 1;
                }
            }

            .arrow-img {
                position: relative;
                left: -20px;
                // top:20px;
                width: 20.83px;
                height: 51.78px;
            }

            .text {
                font-weight: 500;
                font-size: 22px;
                line-height: 150%;
                margin-bottom: 38.54px;
            }

            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #C0C0C0;
                padding-left: 36.19px;
                position: relative;

                .svg-img {

                    height: 24px;
                    width: 24px;
                    position: absolute;
                    left: 0;
                    top: 3px;
                }

                // &::after {
                //     content: "";
                //     display: block;
                // }
            }

            p+p {
                margin-top: 32px;
            }

            .badge {
                margin-top: 80px;
                display: flex;
                justify-content: flex-end;

                .svg-badge {
                    width: 152.57px;
                    height: 152.57px;

                }
            }
        }
    }

    .team {
        margin-top: 85px;

        .title {
            position: relative;
            text-align: center;

            // padding: 0 97.09px 0 148.36px;
            .left {
                position: absolute;
                left: 0;
                height: 77.94px;
                top: 0;
                width: 96.58px;
                color: #C81947;
            }

            .team-img {
                width: 413px;
                height: 186px;
            }

            .right {
                width: 96.58px;
                height: 77.94px;
                position: absolute;
                right: 0;
                top: 0;
                transform: rotateY(180deg);
                color: #C81947;

            }
        }

        .text-1 {
            font-size: 18px;
            line-height: 150%;
            text-align: center;
            color: #C0C0C0;
            // padding: 0 345.37px 0 344.71px;

        }

        .introduction {
            // padding: 48.62px 257.33px 143.52px 258.84px;
            padding-top: 48.62px;
            padding-bottom: 143.52px;
            display: flex;
            justify-content: space-between;

            &>div {
                width: 307.71px;

                .svg-img,img {
                    width: 307.71px;
                    height: 307.71px;
                    position: relative;
                    z-index: 33;
                }

                div {
                    z-index: 33;
                    position: relative;
                }
            }

            .weik {
                position: relative;

                .effect-2 {
                    position: absolute;
                    background: #AC00EA;
                    width: 298.26px;
                    height: 298.26px;
                    filter: blur(170px);
                    opacity: 0.6;
                    top: 0;
                    left: 0;
                    z-index: 2;
                }
            }

            .jhon {
                position: relative;

                .effect-3 {
                    position: absolute;
                    background: #C81947;
                    width: 298.26px;
                    height: 298.26px;
                    filter: blur(190px);
                    opacity: 0.6;
                    top: 0;
                    z-index: 2;
                }
            }

            .peter {
                position: relative;

                .effect-4 {
                    position: absolute;
                    background: #300DC5;
                    width: 298.26px;
                    height: 298.26px;
                    filter: blur(170px);
                    opacity: 0.6;
                    top: 0;
                    z-index: 2;
                }
            }

            .name {
                margin-top: 22.11px;
                font-weight: 500;
                font-size: 27px;
                line-height: 150%;
                text-align: center;
            }

            .acter {
                margin-top: 4px;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                text-align: center;
                color: #C0C0C0;
                font-family: 'Inter';
            }
        }
    }
}

@media screen and (max-width:1080px) {
    .about {
        width: 398px;
        margin-left: auto;
        margin-right: auto;

        .effect-1 {
            display: none;
        }

        .effect-2, .effect-3, .effect-4 {
            transform: translateZ(0);
        }

        .about-us {
            flex-direction: column;
            padding-top: 0;

            .about-img {
                display: none;
            }

            .about-img2 {
                display: block;
                width: 398px;
                height: 307.95px;
            }

            .us-text {
                margin-left: 0;

                .title {
                    left: 0;
                    margin-top: 18px;
                    height: 56px;

                    // height: 56px;
                    .svg-img {
                        width: 177px;
                        height: 56px;
                    }
                }

                .arrow-img {
                    display: none;
                }

                .text {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 120%;
                    margin-bottom: 24px;
                }

                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 160%;
                    padding-left: 30px;
                    color: #C0C0C0;

                    .svg-img {
                        width: 18px;
                        height: 18px;
                    }
                }

                .badge {
                    display: none;
                }
            }
        }

        .team {
            .title {
                .left {
                    width: 58px;
                    height: 47.53px;
                    margin-left: 0;
                    top: -20px;
                    color: #E7D9D9;
                }

                .right {
                    width: 58px;
                    height: 47.53px;
                    margin-right: 0;
                    top: -20px;
                    color: #E7D9D9;
                }

                .team-img {
                    width: 200px;
                    height: 90px;
                }
            }

            .text-1 {
                font-size: 14px;
                line-height: 160%;
                text-align: center;
                color: #C0C0C0;
                padding: 0 19px;
            }

            .introduction {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-bottom: 0;

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .acter {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 150%;
                        color: #C0C0C0;
                        margin-bottom: 40px;
                    }

                    .svg-img,img {
                        width: 264.5px;
                        height: 264.5px;
                        position: relative;
                        z-index: 33;
                    }

                }
            }
        }
    }


}
</style>