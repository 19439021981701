<!--  -->
<template>
    <div class="buy-container">
        <div class="effect-1"></div>

        <div class="effect-2"></div>
        <div class="effect-3" v-if="props.openMenu"></div>
        <div class="effect-4" v-if="props.openMenu"></div>
        <div class="effect-5" v-if="props.openMenu"></div>
        <div class="back"></div>
        <div class="title">
            <div class="title-left">
                <h1>What You Might Want to Know</h1>
                <svg-icon icon-class="badge" class="badge" />
            </div>
            <!-- <svg-icon icon-class="man" class="three" /> -->
            <img class="three" src="@/assets/images/image1.png" alt="">

        </div>
        <div class="buy-main">
            <div class="question-1">What are the benefits of becoming a member of MaskMan?</div>
            <div class="answer-1">
                <div class="left">
                    <!-- <img class="arrow-1" src="@/assets/images/arrow_1.png" alt=""> -->
                    <svg-icon icon-class="arrow_1" class="arrow-1" />
                    <div class="left-1">
                        <p> Receive generous rewards and exclusive airdrop opportunities from our latest
                            innovative projects. These projects are carefully planned by our team, with huge development
                            potential and the ability to bring more opportunities and possibilities to the crypto space.</p>
                    </div>
                    <!-- <img class="arrow-3" src="@/assets/images/arrow_3.png" alt=""> -->
                    <svg-icon icon-class="arrow_3" class="arrow-3" />

                    <div class="left-2">
                        <p>
                            Own a one-of-a-kind digital art piece that serves not only as a personal
                            collectible but also as a symbol of identity and community recognition.
                        </p>
                    </div>
                </div>
                <!-- <svg-icon icon-class="toy" class="image-2" /> -->
                <!-- <svg-icon icon-class="toy_mobile" class="image-2-mobile" /> -->

                <img class="image-2" src="@/assets/images/image2.png" alt="">
                <img class="image-2-mobile" src="@/assets/images/image2_mobile.png" alt="">
                <div class="right">
                    <!-- <img class="arrow-2" src="@/assets/images/arrow_2.png" alt=""> -->
                    <svg-icon icon-class="arrow_2" class="arrow-2" />

                    <div class="right-1">
                        <p>
                            Become part of a community where you can interact with other art enthusiasts,
                            investors, and gamers, and explore the future of digital art and the crypto world together.
                        </p>
                    </div>
                    <!-- <img class="arrow-4" src="@/assets/images/arrow_4.png" alt=""> -->
                    <svg-icon icon-class="arrow_4" class="arrow-4" />

                    <div class="right-2">
                        <p>
                            We are committed to providing more value to MaskMan members, ensuring that your
                            participation and support are well rewarded.</p>
                    </div>
                </div>
            </div>
            <div class="question-2">
                <div class="question">
                    <div>
                        <!-- <img class="arrow-img" src="@/assets/images/arrow.png" alt=""> -->
                        <svg-icon icon-class="arrow" class="arrow-img" />

                        <div class="q-title">Who will be the <br> leader of Maskman</div>
                    </div>
                    <img class="image-3" src="@/assets/images/image3.png" alt="">
                    <img class="image-3-mobile" src="@/assets/images/image3_mobile.png" alt="">
                    <!-- <svg-icon icon-class="image_3" class="image-3" /> -->
                    <!-- <svg-icon icon-class="image_3_mobile" class="image-3-mobile" /> -->
                </div>
                <div class="answer-2">
                    <p>
                        At the moment, the founding members of MaskMan are taking on this role, and we will do our best to
                        fulfill the expectations of our community members. At the same time, we welcome all community
                        members who are passionate about contributing to MaskMan to join us. If you have the enthusiasm and
                        ability, you can become a leader and contribute to the prosperity and development of the community.
                    </p>
                    <p>
                        If you believe that you or someone else has the ability to take on this role, please contact us
                        through Discord or send an email to hi@MaskMan.com. We look forward to your participation in
                        building a more prosperous MaskMan community.
                    </p>
                </div>
            </div>
        </div>
        <div class="time" >
            <h1>Mint it</h1>
            <h2>You can mint maximum NFTs here!</h2>
            <div class="cards">
                <div class="card">
                    <svg-icon icon-class="border" class="card-border" />
                    <svg-icon icon-class="border_white" class="card-border border-white" />

                    <!-- <img class="card-border" src="@/assets/images/border2.png" alt=""> -->
                    <!-- <img class="card-border border-white" src="@/assets/images/border.png" alt=""> -->
                    <div class="card-text">
                        <div class="card-title">Supply</div>
                        <div class="card-text-1">10k</div>
                    </div>
                </div>
                <div class="card">
                    <!-- <img class="card-border" src="@/assets/images/border2.png" alt="">
                    <img class="card-border border-white" src="@/assets/images/border.png" alt=""> -->
                    <svg-icon icon-class="border" class="card-border" />
                    <svg-icon icon-class="border_white" class="card-border border-white" />
                    <div class="card-text">
                        <div class="card-title">Allowlist</div>
                        <div class="card-text-1">0.00ETH</div>
                        <div class="card-text-2">April 30 at 12:00 AM GMT+8</div>
                    </div>
                </div>
                <div class="card">
                    <!-- <img class="card-border" src="@/assets/images/border2.png" alt="">
                    <img class="card-border border-white" src="@/assets/images/border.png" alt=""> -->
                    <svg-icon icon-class="border" class="card-border" />
                    <svg-icon icon-class="border_white" class="card-border border-white" />
                    <div class="card-text">
                        <div class="card-title">Public</div>
                        <div class="card-text-1">0.05ETH</div>
                        <div class="card-text-2">May 2 at 12:00 AM GMT+8</div>
                    </div>
                </div>
            </div>
            <div class="btn-container" id="mint">
                <el-button id="mintBtn" class="mint-button" type="primary" @click="mintFn" :disabled="!mintAble">Mint
                    now</el-button>
            </div>
        </div>
        <div class="question-3">
            <div class="star-container">
                <svg-icon icon-class="cross_star" class="svg-img star1" />
                <svg-icon icon-class="cross_star" class="svg-img star2" />
                <!-- <svg-icon icon-class="group_img_1" class="group-img-1" /> -->
                <img class="group-img-1" src="@/assets/images/groupimg_1.png" alt="">
                <svg-icon icon-class="cross_star" class="svg-img star3" />
                <svg-icon icon-class="cross_star" class="svg-img star4" />
            </div>
            <div class="q3-container">
                <div class="q3-title">
                    Should i buy Maskman?
                </div>
                <div class="q3-text">
                    <p>
                        In the rapidly developing blockchain industry, whether to buy MaskMan is not an easy question to
                        answer. The financial loss risk of crypto projects is huge, and only with outstanding creativity and
                        insight can a picture have great value.
                    </p>
                    <p>
                        However, in this industry, only a very small number of NFT projects can achieve this, and most NFTs
                        may be worthless. In this industry full of speculation and hype, it is wise to always maintain a
                        skeptical attitude.
                    </p>
                    <p>
                        MaskMan has a clear long-term development plan. If you want to explore adventures in the blockchain
                        world, we sincerely invite you to become our supporter, explore the infinite possibilities of the
                        blockchain world together, contribute to the success of MaskMan, and create a better future
                        together.
                    </p>
                </div>
            </div>
        </div>
        <div class="commitment">
            <div class="text-container">
                <!-- <img class="commit-text commit-text1" src="@/assets/images/Commitment1.png" alt=""> -->
                <!-- <img class="commit-text " src="@/assets/images/Commitment2.png" alt=""> -->
                <svg-icon icon-class="Commitment" class="svg-img  commit-text1" />
                <svg-icon icon-class="Commitment2" class="svg-img" />
                <div class="text">
                    <p>
                        The MaskMan community is committed to providing an open and free environment, and we encourage all
                        members to actively participate in community Interactions. Any member holding an NFT can enjoy
                        freedom of speech in the Discord channel, whether you support MaskMan or not.
                    </p>
                    <p>
                        However, please note that It is best to ensure that your words and actions comply with the laws of
                        the country in which you reside, otherwise, you may face trouble. At the same time, any malicious
                        behavior intended to harm others within the community will not be tolerated.

                    </p>
                    <p>
                        We reserve the right to impose penalties such as muting or other measures and cooperate with
                        government law enforcement agencies In Investigating related actions. As community members, we work
                        together to create a healthy, friendly, and open community environment where everyone can freely
                        express their opinions and share their thoughts.
                    </p>
                    <p>
                        We look forward to all members working together to ensure the prosperity and development of the
                        community.
                    </p>
                </div>
            </div>
            <img class="commit-img1" src="@/assets/images/groupimg_2.png" alt="">
            <!-- <svg-icon icon-class="group_img_2" class="commit-img1" /> -->

        </div>
    </div>
</template>

<script setup>
import { ElButton } from "element-plus"
import { ref, defineProps, onMounted } from 'vue'
const mintAble = ref(false)
const time = ref('2023/05/30 12:00:00')
// const time = ref('2023-04-21 09:39:00')
onMounted(() => {
    let date = new Date().getTime()
    let newdate = new Date(time.value).getTime()
    let timedown = newdate - date
    setTimeout(() => {
        mintAble.value = true;
    }, timedown);
})
const props = defineProps({
    openMenu: Boolean
})

const mintFn = () => {
    document.getElementById("mintBtn").blur()
    window.open('https://www.baidu.com', '_blank')
}


</script>
<style scoped lang="scss">
.buy-container {
    width: 1168px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    .back {
        background-image: linear-gradient(181.81deg, #000207 3.06%, rgba(0, 0, 0, 0) 49.18%, #000207 98.47%, #000000 98.47%), url('../assets/images/back2.png');
        position: absolute;
        width: 2018px;
        height: 861.71px;
        left: -253px;
        top: 2424.56px;
        z-index: 1;
    }

    .effect-2 {
        position: absolute;
        width: 342.05px;
        height: 670.53px;
        left: 1172px;
        right: 0;
        top: 195.15px;
        background: #300DC5;
        opacity: 0.4;
        filter: blur(200px);
        pointer-events: none;
        z-index: 2;

    }

    .effect-3 {
        position: absolute;
        width: 342.05px;
        height: 670.53px;
        right: 0;
        top: 1390.47px;
        background: #C81947;
        opacity: 0.6;
        filter: blur(250px);
        pointer-events: none;
        z-index: 2;

    }

    .effect-4 {
        position: absolute;
        width: 542.05px;
        height: 670.53px;
        left: -222.11px;
        top: 1888.44px;

        z-index: 2;
        background: #300DC5;
        opacity: 0.4;
        filter: blur(250px);
        pointer-events: none;

    }

    .effect-5 {
        position: absolute;
        width: 601.87px;
        height: 886.94px;
        // left: 768.82px;
        right: 0;
        top: 3423.38px;
        z-index: 2;

        background: #300DC5;
        opacity: 0.3;
        pointer-events: none;

        filter: blur(250px);
    }


    .title {
        display: flex;
        padding-top: 48.2192px;
        position: relative;
        z-index: 33;

        .title-left {
            position: relative;
            z-index: 33;
            flex: 1;

            h1 {
                font-weight: 700;
                font-size: 77.0736px;
                line-height: 120%;
            }

            .badge {
                margin-top: 193.8704px;
                height: 152.72px;
                width: 152.72px;
            }
        }

        .three {
            margin-left: 90.8192px;
            width: 590.71px;
            height: 714.83px;
        }
    }

    .buy-main {
        margin-top: 133.82px;
        position: relative;
        z-index: 33;

        .question-1 {
            font-weight: 700;
            font-size: 48px;
            line-height: 130%;
            text-align: center;
        }

        .answer-1 {
            display: flex;
            font-weight: 400;
            font-size: 16px;
            // padding: 0 166.03px;
            margin-top: 48px;
            color: #C0C0C0;
            line-height: 150%;
            text-indent: 50px;
            align-content: center;
            justify-content: center;



            .left {
                display: flex;
                flex-direction: column;
                flex: 1;

                .left-1 {
                    margin-right: 41px;
                }

                .left-2 {
                    margin-right: 25px;
                }
            }

            .image-2 {
                width: 300px;
                height: 402px;
            }

            .image-2-mobile {
                display: none;
            }

            .right {
                display: flex;
                flex: 1;
                flex-direction: column;
                font-family: 'Inter';

                .right-1 {
                    margin-left: 42.76px;
                }

                .right-2 {
                    margin-left: 36.8px;
                    ;
                }
            }

            .arrow-1 {
                height: 46px;
                width: 56px;
                margin-left: auto;
                margin-right: 41px;
            }

            .arrow-2 {
                height: 30px;
                width: 82px;
                margin-left: 15px;
            }

            .arrow-3 {
                height: 52px;
                width: 79px;
                margin-left: auto;
                margin-right: 25px;
                margin-top: 41px;
            }

            .arrow-4 {
                margin-top: 94.77px;
                margin-left: 28px;
                height: 46px;
                width: 56px;
            }
        }

        .question-2 {
            margin-top: 100.86px;
            // padding: 0 171px;
            position: relative;
            .question {
                display: flex;

                .arrow-img {
                    position: relative;
                    width: 63.72px;
                    top: -30px;
                    // width: 161.89px;
                    height: 63.72px;
                    // left: 30px;
                }

                .q-title {
                    font-weight: 700;
                    font-size: 67px;
                    line-height: 120%;
                    white-space: nowrap;
                }

                .image-3 {
                    // float: right;
                    width: 526.83px;
                    height: 595.56px;
                    margin-left: 11px;
                    margin-top: 50px;
                }

                .image-3-mobile {
                    display: none;
                }
            }

            .answer-2 {
                background: linear-gradient(90.64deg, rgba(255, 255, 255, 0.12) 0.4%, rgba(255, 255, 255, 0.12) 98.36%);
                backdrop-filter: blur(26px);
                line-height: 150%;
                padding: 24px 78.2px 50.8px 24px;
                border-radius: 8px;
                // margin-right: 430px;
                position: absolute;
                top: 270.38px;
                width: 909.61px;
                font-size: 16px;

                p+p {
                    margin-top: 16.51px;
                }
            }
        }
    }

    .time {
        margin-top: 151.5px;
        position: relative;
        z-index: 33;
        // padding-top: 183px;
        // padding-bottom: 254px;


        h1 {
            font-weight: 700;
            font-size: 67px;
            line-height: 120%;
            text-align: center;
        }

        h2 {
            font-weight: 500;
            font-size: 32px;
            line-height: 150%;
            text-align: center;
            color: #C0C0C0;
            font-family: 'Inter';

        }

        .cards {
            display: flex;
            // padding: 0 261.52px;
            margin-top: 50.56px;
            justify-content: space-around;

            .card {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .card-border {
                    width: 319.65px;
                    height: 187px;
                }

                .border-white {
                    position: absolute;
                    height: 166.91px;
                    top: 10px;
                    width: 289.65px;
                }

                .card-text {
                    position: absolute;
                    font-family: 'Inter';

                    .card-title {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 150%;
                        color: #C0C0C0;
                        margin-top: 30px;
                        text-align: center;
                    }

                    .card-text-1 {
                        font-weight: 700;
                        font-size: 27px;
                        line-height: 150%;
                        text-align: center;
                        color: #FFFFFF;
                        margin-top: 4px
                    }

                    .card-text-2 {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 150%;
                        text-align: center;
                        color: #C0C0C0;
                        margin-top: 12px;
                    }
                }
            }
        }

        .btn-container {
            text-align: center;
            margin-top: 56px;
            // padding-bottom: 100px;

            .mint-button {
                border-radius: 8px;
                width: 187px;
                height: 68px;
                font-family: 'Inter';
                font-size: 24px;
                line-height: 150%;
                color: #FFFFFF;
                &:hover {
                    background-color: #d76886;
                }
            }
        }
    }

    .question-3 {
        margin-top: 250px;
        display: flex;
        // padding: 0 172px;
        align-items: flex-start;
        position: relative;
        z-index: 33;

        .star-container {
            position: relative;

            .svg-img {
                position: absolute;

                &.star1 {
                    width: 61.17px;
                    height: 64.63px;
                    top: -80px;
                    left: 10px;
                }

                &.star2 {
                    width: 25.73px;
                    height: 27.19px;
                    left: 80px;
                    top: -100px;
                }

                &.star3 {
                    width: 52.82px;
                    height: 55.8px;
                    bottom: 0;
                    right: 40px;
                }

                &.star4 {
                    width: 31.93px;
                    height: 33.74px;
                    bottom: -30px;
                    right: 0;
                }
            }

            img {
                width: 345.73px;
                margin-right: 54.25px;
            }

        }

        .q3-title {
            font-weight: 700;
            font-size: 67px;
            line-height: 120%;
            white-space: nowrap;
        }

        .q3-text {
            padding-right: 40px;

            p {
                margin-top: 16px;
                font-family: 'Inter';
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #C0C0C0;
            }
        }
    }

    .commitment {
        margin-top: 192px;
        // padding: 36px 172px 0;
        padding-top: 36px;
        margin-bottom: 140px;
        position: relative;
        display: flex;
        z-index: 33;

        .text-container {

            margin-right: 537px;
            padding-top: 100px;
            position: relative;

            .svg-img {
                position: absolute;
                width: 801.46px;
                height: 80px;
                top: 0;
            }

            .commit-text1 {
                z-index: 2;
            }

            .text p {
                margin-top: 16px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #C0C0C0;
            }
        }

        .commit-img1 {
            position: absolute;
            top: 0;
            width: 497px;
            height: 520px;
            right: 0;
            z-index: 0;
        }

        // .commit-img2 {
        //     position: absolute;
        //     top: 87.58px;
        //     right: 0;
        //     width: 319.27px;
        //     transform: matrix(-1, 0, 0, 1, 0, 0);
        // }

        // .commit-img3 {
        //     display: none;
        // }
    }
}

@media screen and (max-width:1080px) {
    .buy-container {
        width: 100%;

        .back {
            display: none;
        }

        .title {
            padding-top: 20px;
        }

        .effect-2, .effect-3, .effect-4, .effect5, .answer-2 {
            transform: translateZ(0);
        }

        .effect-2 {
            left: auto;
            top: 359.37px;
            width: 298.34px;
            height: 399.09px;
            filter: blur(186.5px);
        }

        .effect-3 {
            width: 331.14px;
            height: 409.63px;
            left: -73.7px;
            top: 2656.55px;
        }

        .effect-4 {
            // width: 542.05px;
            width: 100%;
            height: 670.53px;
            // left: -29.18px;
            right: 0;
            left: 0;
            top: 1591.8px;

        }

        .effect-5 {
            display: none;
        }

        .title {
            flex-direction: column;

            .title-left {
                h1 {
                    font-weight: 700;
                    font-size: 48px;
                    line-height: 125%;
                    text-align: center;
                }

                .badge {
                    display: none;
                }
            }

            .three {
                width: 398px;
                height: 481.63px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 24.95px;
            }
        }

        .buy-main {
            margin-top: 56.63px;
            width: 398px;
            margin-left: auto;
            margin-right: auto;

            .question-1 {
                font-size: 32px;
                line-height: 120%;
                text-align: left;
            }

            .arrow-1,
            .arrow-2,
            .arrow-3,
            .arrow-4,
            .arrow-img {
                display: none;
            }

            .answer-1 {
                flex-direction: column;
                text-indent: 0;
                margin-top: 24px;
                font-size: 14px;

                .left {
                    .left-1 {
                        margin-bottom: 16px;
                    }

                    .left-1,
                    .left-2 {
                        margin-right: 0;
                    }
                }

                .image-2 {
                    display: none;
                }

                .image-2-mobile {
                    display: block;
                    width: 398px;
                    height: 465.66px;
                    margin: 24px 0;
                }

                .right {
                    .right-1 {
                        margin-bottom: 16px;
                    }

                    .right-1,
                    .right-2 {
                        margin-left: 0;
                    }
                }
            }

            .question-2 {
                margin-top: 48px;

                .question {
                    flex-direction: column;

                    .q-title {
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 120%;
                        text-align: center;
                    }

                    .image-3 {
                        display: none;
                    }

                    .image-3-mobile {
                        display: block;
                        margin-top: 32px;
                        margin-bottom: 24px;
                        width: 397.26px;
                    }
                }

                .answer-2 {
                    position: static;
                    width: auto;
                    padding: 0;
                    background: none;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    font-family: 'Inter';
                    color: #C0C0C0;

                    p+p {
                        margin-top: 12px;
                    }
                }
            }

        }

        .time {
            margin-top: 48px;
            padding-bottom: 0;

            h1 {
                font-weight: 700;
                font-size: 32px;
                line-height: 120%;
            }

            h2 {
                font-size: 20px;
                line-height: 150%;
                margin-top: 8px;
            }

            .cards {
                flex-direction: column;

                .card {
                    margin-bottom: 40px;

                    .card-text {
                        .card-title {
                            font-size: 18px;
                        }

                        .card-text-1 {
                            font-size: 22px;
                        }

                        .card-text-2 {
                            font-size: 12px;
                        }
                    }
                }
            }

            .btn-container {
                padding-bottom: 48px;
                .el-button {
                    width: 364px;
                    height: 52px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 150%;
                }
            }
        }

        .question-3 {
            flex-direction: column-reverse;
            padding: 0 17px;
            margin-top: 0px;

            .star-container {
                margin-left: auto;
                margin-right: auto;

                img {
                    margin-right: 0;
                }

                .svg-img {
                    display: none;
                }
            }

            .q3-container {
                position: relative;
                z-index: 33;

                .q3-title {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 120%;
                }

                .q3-text {
                    padding-right: 0;

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 150%;
                        color: #C0C0C0;
                    }

                    p+p {
                        margin-top: 16px;
                    }
                }

            }

            .group-img-1 {
                width: 345.73px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 32px;
            }
        }

        .commitment {
            flex-direction: column;
            margin-top: 0;
            width: 398px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 80px;

            .text-container {
                margin-right: 0;
                padding-top: 50px;

                .svg-img {
                    width: 383px;
                    height: 38px;
                }

                .text {
                    p {
                        font-size: 15px;
                        line-height: 150%;
                        color: #C0C0C0;
                    }

                    p+p {
                        margin-top: 12px;
                    }
                }
            }

            .commit-img1 {
                position: relative;
                width: 345.73px;
                height: 342.27px;
                margin-top: 32px;
                margin-left: auto;
                margin-right: auto;
                // display: none;
            }

            // .commit-img2 {
            //     display: none;
            // }

            // .commit-img3 {
            //     display: block;
            //     width: 345.73px;
            //     margin-left: auto;
            //     margin-right: auto;
            //     margin-top: 32px;
            // }
        }
    }
}
</style>