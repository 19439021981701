<!--  -->
<template>
    <div class="head-container">
        <div class="menu">
            <svg-icon icon-class="vector" class="logo" @click="routerTo('/')" />
            <div class="tab" :class="props.choosed ? 'choosed' : ''">
                <span :class="props.active === 1 ? 'active' : ''" @click="routerTo('buy', 1)">Buy Maskman</span>
                <span :class="props.active === 2 ? 'active' : ''" @click="routerTo('about', 2)">About</span>
                <span :class="props.active === 3 ? 'active' : ''" @click="routerTo('careers', 3)">Careers</span>
                <span :class="props.active === 4 ? 'active' : ''" @click="routerTo('roadmap', 4)">Roadmap</span>
            </div>
            <div class="operation">
                <svg-icon icon-class="data" class="svg-img" @click="openPage()" />
                <svg-icon icon-class="camera" class="svg-img" @click="openPage()" />
                <svg-icon icon-class="twitter" class="svg-img" @click="openPage()" />
                <svg-icon icon-class="game" class="svg-img" @click="openPage()" />
                <el-button id="buyNow" @click="pageSkip()" type="primary">Mint now</el-button>
            </div>
        </div>
        <div class="menu1080">
            <div class="dropmenu">
                <svg-icon icon-class="vector" class="logo" @click="routerTo('/')" />

                <div class="open-menu" @click="menuClick">
                    <span v-if="!menuVisible">
                        <svg-icon icon-class="menu" class="svg-img" />
                    </span>
                    <span v-else>
                        <svg-icon icon-class="close" class="svg-img" />
                    </span>
                </div>
            </div>

            <div class="dropitem" v-show="menuVisible">
                <div class="mint" @click="pageSkip()">
                    <span>Mint now</span>
                    <svg-icon icon-class="mint" class="svg-img" />
                </div>
                <div :class="props.active === 1 ? 'active' : ''" @click="routerTo('buy', 1)">
                    <span>Buy Maskman</span>
                </div>
                <div :class="props.active === 2 ? 'active' : ''" @click="routerTo('about', 2)">
                    <span>About</span>
                </div>
                <div :class="props.active === 3 ? 'active' : ''" @click="routerTo('careers', 3)">
                    <span>Careers</span>
                </div>
                <div :class="props.active === 4 ? 'active' : ''" @click="routerTo('roadmap', 4)">
                    <span>Roadmap</span>
                </div>
                <div class="operation" @click="openPage()">
                    <span>Docs</span>
                    <svg-icon icon-class="data" class="svg-img" />
                </div>
                <div class="operation" @click="openPage()">
                    <span>Discord</span>
                    <svg-icon icon-class="game" class="svg-img" />
                </div>
                <div class="operation" @click="openPage()">
                    <span>Twitter</span>
                    <svg-icon icon-class="twitter" class="svg-img" />
                </div>
                <div class="operation" @click="openPage()">
                    <span>Instagram</span>
                    <svg-icon icon-class="camera" class="svg-img" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ElButton } from "element-plus"
import { defineEmits, defineProps, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { setTimeout } from "timers";

const route = useRoute();
const router = useRouter();
const emits = defineEmits(["routerTo", "openMenuFn"])
const routerTo = (url) => {
    menuVisible.value = false
    emits("routerTo", url)
    emits("openMenuFn", true)
}
const props = defineProps({
    active: Boolean,
    choosed: String
})
const menuVisible = ref(false)
const menuClick = () => {
    menuVisible.value = !menuVisible.value
    emits("openMenuFn", !menuVisible.value)
}

const openPage = (url = 'https://www.baidu.com') => {
    window.open(url, '_blank')
}



const pageSkip = () => {//第一个参数是路由地址，第二个参数是跳转后锚点定位的位置，第二个参数后面的？是因为其他页面跳转也用的这个方法，但是不用传参数所以用？表示该参数可有可无（ts）
    menuVisible.value = false
    emits("openMenuFn", true)
    if (route.path !== '/buy') {//通过路由判断是当前页面使用锚点定位还是跨页面使用锚点定位
        router.push({ path: '/buy' })
        setTimeout(() => {
            document.querySelector('#mint').scrollIntoView(false);
        })
    } else {
        setTimeout(() => {
            document.querySelector('#mint').scrollIntoView(false);
        })
    }
    document.getElementById("buyNow").blur()

}
</script>
<style scoped lang="scss">
.head-container {
    z-index: 99;
    // padding: 32px 172px 0px;
    padding-top: 32px;
    width: 1168px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .logo {
        // font-weight: 700;
        // font-size: 32px;
        // line-height: 150%;
        // margin-right: 140px;
        // z-index: 1;
        width: 140px;
        height: 22.44px;

        cursor: pointer;
    }

    .menu {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .choosed {
            span {
                color: #C0C0C0;
            }
        }

        .tab {
            display: flex;

            span {
                display: inline-block;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                padding: 4px 10px;
                white-space: nowrap;
                color: #C0C0C0;
                cursor: pointer;
                &:hover{
                    color: #FFF;
                }
            }

            .active {
                background: #232122;
                border-radius: 40px;
                color: #fff;
            }

            span+span {
                margin-left: 32px;
            }
        }


    }

    .operation {
        display: flex;
        align-items: center;

        .svg-img {
            height: 16px;
            width: 16px;
            margin-right: 24px;
            cursor: pointer;
            color: #fff;

            &:hover {
                color: #D93460;
            }
        }

        .el-button {
            border-radius: 8px;
            // width: 93px;
            height: 43px;
            min-height: 22px;
            font-family: 'Inter';
            font-size: 14px;
            line-height: 150%;
            color: #FFFFFF;
            &:hover {
                background-color: #D93460;
            }
        }
    }

    .menu1080 {
        display: none;
    }


}

@media screen and (max-width: 1080px) {
    .head-container {
        width: 100%;

        // min-height: 100vh;
        // height: 80px;
        .menu {
            display: none;
        }

        .menu1080 {
            display: block;
            width: 100%;
            // height: 100%;

            .dropmenu {
                padding: 0 16px 16px;
                // border-bottom: 1px solid #A6AEBB66;
                display: flex;
                align-items: center;

                .logo {
                    width: 100px;
                }

                .open-menu {
                    flex: 1;
                    // margin-left: auto;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #FFFFFF;
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    cursor: pointer;
                }

                .svg-img {
                    height: 18px;
                    width: 18px;

                }
            }

            .dropitem {
                height: 100vh;
                box-sizing: border-box;
                padding: 0 16px;
                position: absolute;
                background: rgba(15, 14, 20, 0.9);
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
                transform: translateZ(0);
                min-height: 100%;
                width: 100%;
                z-index: 999;

                .mint {
                    color: #c81947;

                    .svg-img {
                        height: 18px;
                        width: 20px;
                    }
                }

                div {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 150%;
                    color: #C0C0C0;
                    padding: 16px 0;
                    cursor: pointer;
                    border-bottom: 1PX solid rgba(166, 174, 187, 0.4);
                    display: flex;
                    justify-content: space-between;

                    .svg-img {
                        margin-right: 0;
                    }
                }

                .active {
                    color: #fff;
                }

            }
        }
    }

}
</style>