<!--  -->
<template>
    <div class="roadmap">
        <div class="effect-1"></div>
        <div class="effect-2"></div>
        <img class="star-back" src="@/assets/images/star.png" alt="">
        <img class="star-back-2" src="@/assets/images/star_back_2.png" alt="">
        <!-- <svg-icon icon-class="back_2" class="star-back" /> -->
        <!-- <svg-icon icon-class="back_3" class="star-back-2" /> -->

        <div class="future">
            <div class="left">
                <div class="text">
                    About the Future
                </div>
                <h1>Our Future <br>Plans Beyond the <br>MaskMan Project</h1>
                <!-- <img class="badge" src="@/assets/icons/badge.png" alt=""> -->
                <svg-icon icon-class="badge" class="badge" />
            </div>
            <div class="right">
                <!-- <svg-icon icon-class="photos" class="photos" /> -->
                <img src="@/assets/images/photos.png" alt="" class="photos">
                <p>
                    The MaskMan project is an important milestone for our team, but it is only a small part of our future
                    plans. We are constantly thinking about the future and are committed to creating more innovative and
                    long-term valuable projects.
                </p>
                <p>
                    Our plans include developing universal game tokens, fun games, and new types of crypto wallets to meet
                    the ever-changing needs of the crypto community. We believe these innovations will greatly enhance the
                    usability and reliability of the crypto space, bringing positive impacts to the crypto world.
                </p>
            </div>
        </div>
        <div class="title-container">
            <svg-icon icon-class="roadmap" class="title-img" />
            <svg-icon icon-class="roadmap_mobile" class="title-img-mobile" />
            <!-- <img class="title-img" src="@/assets/images/roadmap.png" alt=""> -->
            <!-- <img class="title-img-mobile" src="@/assets/images/roadmap_mobile.png" alt=""> -->
        </div>
        <div class="bottom-back">
            <!-- <svg-icon icon-clascss="stars" class="stars" /> -->
            <img class="stars" src="@/assets/images/stars.png" alt="">
            <div class="bottom">
                <div class="text-container">
                    <div class="right">
                        <div class="rocket-container">
                            <!-- <svg-icon icon-class="rocket" class="rocket" /> -->
                            <img class="rocket" src="@/assets/images/rocket.png" alt="">
                            <svg-icon icon-class="rocket_back" class="back" />
                            <svg-icon icon-class="rocket_back_mobile" class="back-modile" />
                            <svg-icon icon-class="line" class="line" />

                            <!-- <img class="back" src="@/assets/images/rocket_back.png" alt=""> -->
                            <!-- <img class="back-modile" src="@/assets/images/rocket_back_mobile.png" alt=""> -->
                            <!-- <img class="line" src="@/assets/images/rocket_line.png" alt=""> -->
                        </div>
                        <div class="text">
                            <div class="title">Hero Competitive Mobile Game</div>
                            <div class="subtitle"> - Expected Launch Time: October-December 2023</div>
                            <p>
                                This is an innovative hero competitive mobile game, available for both single-player and
                                multiplayer battles. The game utilizes NFT technology, with each character and item
                                possessing
                                unique identities and attributes, allowing you to experience unprecedented depth and
                                strategy in
                                the game. The game offers various game modes and challenges, which you can tackle alone or
                                team
                                up with friends. Unlike other crypto games, it is not a Ponzi game designed specifically for
                                crypto enthusiasts but a crypto game created for the broader gaming community. We will take
                                fun
                                as the starting point and let more non-cryptophiles participate in the crypto world through
                                the
                                game to realize the healthy and sustainable development of the game.
                            </p>
                        </div>
                    </div>
                    <div class="right">
                        <div class="rocket-container">
                            <img class="rocket" src="@/assets/images/rocket.png" alt="">
                            <svg-icon icon-class="rocket_back" class="back" />
                            <svg-icon icon-class="rocket_back_mobile" class="back-modile" />
                            <!-- <svg-icon icon-class="rocket" class="rocket" /> -->
                            <!-- <svg-icon icon-class="line" class="line" /> -->
                            <!-- <img class="back" src="@/assets/images/rocket_back.png" alt=""> -->
                            <!-- <img class="back-modile" src="@/assets/images/rocket_back_mobile.png" alt=""> -->
                        </div>
                        <div class="text">
                            <div class="title">Universal Crypto Game Token</div>
                            <div class="subtitle"> - Expected Launch Time: 2024</div>
                            <p>
                                Our next step is to develop a universal game token, a stablecoin specifically designed for
                                circulation between different games, used for in-game transactions and reward systems,
                                enabling
                                gamers to have 100% control over their gaming assets. The token has the advantages of
                                decentralization and universality, making the gaming industry more innovative and
                                developmental.
                                Our goal is to provide a better gaming experience for players while bringing more
                                opportunities
                                and value to the gaming industry. Both developers and players will benefit from and enjoy
                                our
                                universal game token.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>
<style scoped lang="scss">
.roadmap {

    .future {
        position: relative;
        z-index: 33;
        width: 1168px;
        margin-left: auto;
        margin-right: auto;
        // padding: 65.91px 172px 0;
        padding-top: 65.91px;
        display: flex;

        .left {
            .text {
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                color: #C81947;
            }

            h1 {
                font-weight: 700;
                font-size: 53px;
                line-height: 130%;
                color: #FFFFFF;
                margin-top: 15.7px;
                white-space: nowrap;
            }

            .badge {
                margin-top: 217.2px;
                width: 152.57px;
                height: 152.57px;
            }
        }

        .right {
            margin-left: 40px;

            .photos {
                width: 668px;
                height: 317.08px;
            }

            p {
                margin-top: 23.24px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #C0C0C0;
            }
        }
    }

    .title-container {
        width: 1168px;
        margin-left: auto;
        margin-right: auto;
        // padding: 110.11px 172px 0;
        padding-top: 110.11px;

        .title-img {
            width: 1168px;
            height: 297px;
        }

        .title-img-mobile {
            display: none;
        }
    }

    .star-back {
        position: absolute;
        width: 1512px;
        height: 1204.82px;
        right: 0;
        // z-index: 0;
        // left: -339.74px;
        top: 1323.05px;
        // transform: rotate(2deg)
    }

    .star-back-2 {
        display: none;
    }

    .bottom-back {
        position: relative;
        // background: url('../assets/images/star.png');
        // background-size: 100%;
        background-repeat: no-repeat;
        min-height: 1104.82px;
        margin-bottom: 100px;
        background-position: 0;
        background-position: 0 50px;
        z-index: 2;


        .stars {
            position: absolute;
            top: 622px;
            left: 172px;
            width: 699.19px;
            height: 413.4px;
        }
    }

    .bottom {
        width: 1168px;
        margin-left: auto;
        margin-right: auto;
        padding: 110.11px 0 0;

        position: relative;



        .text-container {
            // width: 1168px;
            // margin-right: auto;
            width: 50%;
            margin-left: auto;

            .right {
                display: flex;

                .rocket-container {
                    position: relative;
                    width: 70px;
                    flex-shrink: 0;

                    .rocket {
                        position: absolute;
                        top: 0;
                        width: 37.78px;
                        height: 53.97px;
                        z-index: 2;
                    }

                    .back {
                        position: absolute;
                        left: 1.5px;
                        top: 18px;
                        width: 70.57px;
                        height: 41.81px;
                        z-index: 1;
                    }

                    .back-modile {
                        position: absolute;
                        left: 2px;
                        top: 19px;
                        // z-index: 3;
                        display: none;
                    }

                    .line {
                        position: absolute;
                        left: 18px;
                        top: 51px;
                        width: 3px;
                        height: 363.4px;
                    }
                }

                .text {
                    // margin-left: 28px;
                    z-index: 1;

                    .title {
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 150%;
                        margin-top: 20px;
                    }

                    .subtitle {
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 120%;
                        color: #C0C0C0;
                        margin-top: 15.56px;
                        margin-bottom: 32px;
                        font-family: 'Inter';
                    }

                    p {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 150%;
                        color: #C0C0C0;
                        padding-top: 16px;
                        border-top: 1px solid #616161;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1080px) {
    .roadmap {
        .star-back {
            display: none;
        }

        .star-back-2 {
            position: absolute;
            width: 430px;
            height: 396.26px;
            right:0;
            top: 900.45px;
            display: block;
        }

        .effect-2 {
            position: absolute;
            width: 193.41px;
            height: 317.5px;
            // left: 295.59px;
            right: 0;
            top: 778.82px;

            background: #300DC5;
            opacity: 0.3;
            filter: blur(100px);
            transform: translateZ(0);
        }

        .future {
            width: auto;
            padding: 0 16px;
            padding-top: 30px;
            flex-direction: column;

            .left {
                .text {
                    font-size: 10px;
                    line-height: 120%;

                }

                h1 {
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 120%;
                }

                .badge {
                    display: none;
                }
            }

            .right {
                margin-left: 0;

                .photos {
                    width: 397.91px;
                    height: 188.88px;
                    margin-top: 24px;
                }

                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 120%;
                }
            }
        }

        .title-container {
            text-align: center;
            width: auto;
            padding-top: 48px;

            .title-img {
                display: none;
            }

            .title-img-mobile {
                margin-left: auto;
                margin-right: auto;
                display: block;
                width: 252px;
                height: 73.31px;
            }
        }

        .bottom-back {
            min-height: auto;
            background-position: 0 200px;

            .stars {
                display: none;
            }

            .bottom {
                width: auto;
                padding: 16px 0;

                .text-container {
                    width: auto;
                    padding: 0 8px;

                    .right {
                        .rocket-container {
                            width: 37.78px;

                            .back {
                                display: none;
                            }

                            .back-modile {
                                display: block;
                                height: 39.81px;
                                width: 40.45px;
                            }
                        }

                        .text {
                            margin: 0 5.64px;

                            .title {
                                margin-top: 28px;
                                font-size: 16px;
                                line-height: 120%;
                            }

                            .subtitle {
                                font-size: 12px;
                                line-height: 160%;
                                margin-bottom: 10px;
                                margin-top: 8px;
                            }

                            p {
                                font-size: 14px;
                                line-height: 150%;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>