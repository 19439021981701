// 设置 rem 函数

const baseSize = 16
function setRem() {
  if (document.documentElement.clientWidth < 1080) {
	const scale = document.documentElement.clientWidth / 430
	// 设置页面根节点字体大小
	document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
  } else {
	const scale = document.documentElement.clientWidth / 1512
	// 设置页面根节点字体大小
	document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
  }
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};

