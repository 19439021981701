<!--  -->
<template>
    <div class="careers">
        <!-- <svg-icon icon-class="back_1" class="back" /> -->
        <img class="back" src="../assets/images/back.png" alt="">
        <div class="title">
            <h1>Careers</h1>
            <p>
                Decentralization technology can bring about transformative changes and opportunities to the world, promoting
                greater justice and equality. We welcome all who are interested in decentralization technology to join us in
                shaping its future and sharing our progress and achievements.
            </p>
        </div>
        <div class="collapse">
            <el-collapse  @change="handleChange">
                <el-collapse-item name="1">
                    <template #title>
                        <div class="coll-title">
                            <div class="left">
                                <div>Game Backend Developer - Remote</div>
                                <div class="tag">
                                    <span>Full Time</span>
                                    <span>Work From Home</span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="coll-main">
                        <div>Responsibilities</div>
                        <p> <svg-icon icon-class="star" class="svg-img" />Develop game backend solutions based on Unreal
                            Engine</p>
                        <p> <svg-icon icon-class="star" class="svg-img" />Design, build, test, and deliver game-related
                            backends Requirements</p>
                        <div>Requirements</div>
                        <p> <svg-icon icon-class="star" class="svg-img" />Proficient in C++, data structures, algorithms,
                            and common design pattern</p>
                        <p> <svg-icon icon-class="star" class="svg-img" />Experience in Unreal Engine development and
                            optimization; proficiency in engine network</p>
                        <p> <svg-icon icon-class="star" class="svg-img" />Familiarity with microservices, cloud-native
                            design, and database theory is a plus</p>
                        <p> <svg-icon icon-class="star" class="svg-img" />At least 2 years of experience delivering
                            production-level code in a professional environment</p>
                        <div>Pluses</div>
                        <p> <svg-icon icon-class="star" class="svg-img" />Experience in performance analysis and code
                            optimization</p>
                        <p> <svg-icon icon-class="star" class="svg-img" />Degree in Computer Science, Engineering,
                            Mathematics, Physics, or a related field</p>
                        <p> <svg-icon icon-class="star" class="svg-img" />Deep player of card or strategy games</p>
                        <div class="btn" >
                            <el-button type="default">Apply</el-button>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <el-collapse  @change="handleChange">
                <el-collapse-item name="2">
                    <template #title>
                        <div class="coll-title">
                            <div class="left">
                                <div>Game System Designer - Remote</div>
                                <div class="tag">
                                    <span>Full Time</span>
                                    <span>Work From Home</span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="coll-main">
                        <div>Responsibilities</div>
                        <p> <svg-icon icon-class="star" class="svg-img" />Responsible for the game system design work, such
                            as: matchmaking system, gameplay system, and
                            economic system</p>
                        <p><svg-icon icon-class="star" class="svg-img" /> Responsible for building, iterating, and
                            optimizing numerical frameworks</p>
                        <p> <svg-icon icon-class="star" class="svg-img" />Responsible for establishing a commercialization
                            model</p>
                        <div>Requirements</div>
                        <p> <svg-icon icon-class="star" class="svg-img" />At least 2 years of experience in system or
                            numerical planning</p>
                        <p> <svg-icon icon-class="star" class="svg-img" />Rich gaming experience, passionate about card or
                            strategy games</p>
                        <p> <svg-icon icon-class="star" class="svg-img" />Possess excellent numerical sensitivity and can
                            depict game experience through numbers</p>
                        <p> <svg-icon icon-class="star" class="svg-img" />Strong numerical calculation and system modeling
                            capabilities</p>
                        <div class="btn" >
                            <el-button type="default">Apply</el-button>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>

    </div>
</template>

<script setup>
import { ElCollapse, ElCollapseItem, ElButton } from 'element-plus';

const handleChange = (val) => {
    console.log(val)
}
</script>
<style scoped lang="scss">
.careers {
    z-index: 33;

    .back {
        width: 1512px;
        height: 437.99px;
        position: absolute;
        z-index: 0;
        top: 0;
    }

    .title {
        position: relative;
        z-index: 33;
        padding: 64px 0 100.85px 0;
        color: #fff;

        // background-image: url('../assets/images/back.png');
        // background-repeat: no-repeat;
        // background-size: 100vw;
        // background-position: 0 -100px;


        h1 {
            z-index: 33;
            width: 1168px;
            margin-left: auto;
            margin-right: auto;
            font-weight: 500;
            font-size: 43px;
            line-height: 130%;
            z-index: 11;
            color: #fff;
            margin-bottom: 16px;
        }

        p {
            width: 1168px;
            margin-left: auto;
            margin-right: auto;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #C0C0C0;
        }
    }

    .collapse {
        width: 1168px;
        margin-left: auto;
        margin-right: auto;
        padding: 50.1px 0 158px 0;

        .el-collapse {
            border: none;


            .coll-title {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 24px 0;

                .left {
                    div {
                        font-weight: 500;
                        font-size: 27px;
                        line-height: 150%;
                        color: #fff;
                    }

                    .tag {
                        span {
                            font-family: 'Inter';
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 150%;
                            color: #C0C0C0;
                            padding: 0px 16px;
                        }

                        span+span {
                            border-left: 1px solid #616161;
                        }

                        span:first-of-type {
                            padding-left: 0
                        }
                    }
                }

                .right {
                    margin-left: auto;

                }

                .btn-mobile {
                    margin-top: 12px;
                    display: none;
                }

                .el-button {
                    margin-right: 55px;
                    background: #fff0;
                    color: #fff;
                }
            }

            ::v-deep .el-collapse-item {
                .el-collapse-item__header {
                    background: #fff0;
                    border-bottom: 1px solid #2D2A41;

                    height: 100%;

                }


                .el-collapse-item__wrap {
                    background: #fff0;
                    border: none;

                }

                .el-collapse-item__arrow {
                    color: #fff;
                    height: 16px;
                    width: 16px;

                    svg {
                        height: 16px;
                        width: 16px;
                    }
                }
            }

            .coll-main {

                div {
                    margin: 24px 0;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 150%;
                    color: #FFFFFF;
                }

                p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    color: #C0C0C0;
                    // padding-left: 22px;
                    position: relative;

                    .svg-img {
                        margin-right: 8px;
                    }

                    // &::after {
                    //     content: "";
                    //     display: block;
                    //     height: 13px;
                    //     width: 13px;
                    //     position: absolute;
                    //     left: 0;
                    //     top: 3px;
                    //     background: url("../assets/icons/star.png");
                    // }
                }

                p+p {
                    margin-top: 12px;
                }

                .btn {
                    .el-button {
                        background: #fff0;
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1080px) {
    .careers {
        padding: 0 16px;

        .back {
            width: 622.91px;
            height: 180.45px;
            right: 0;
        }

        .title {
            padding: 0;
            background-position: 0 -20px;

            h1 {
                padding-top: 41.73px;
                width: auto;
                font-size: 28px;
                line-height: 120%;
                margin-bottom: 16px;
            }

            p {
                width: auto;
                font-size: 14px;
                line-height: 160%;
            }
        }

        .collapse {
            width: auto;
            padding-bottom: 90px;

            .el-collapse {
                .coll-title {
                    .left {
                        div {
                            font-size: 24px;
                            line-height: 140%;
                        }

                        .tag {
                            span {
                                font-size: 12px;
                            }
                        }
                    }

                    .right {
                        display: none;
                    }

                    .btn-mobile {
                        display: block;
                    }
                }

                .coll-main {
                    div {
                        font-size: 16px;
                        line-height: 150%;
                        margin: 16px 0;
                    }

                    p {
                        font-size: 12px;
                        line-height: 150%;

                        &::after {
                            top: 0
                        }
                    }
                }
            }
        }
    }
}
</style>