<template>
  <div class="main">

    <Head @routerTo="routerTo" @openMenuFn="openMenuFn" :choosed="choosed" :active="active"></Head>
    <div :class="!openMenu ? 'test' : ''">
      <router-view :openMenu="openMenu" />
    </div>
    <Footer @routerTo="routerTo" :choosed="choosed" :active="active"></Footer>
  </div>
</template>
<script setup>
import Head from '@/components/Head.vue';
import Footer from './components/Footer.vue';
import { useRouter, useRoute } from 'vue-router'
import { ref, watchEffect } from 'vue'
const route = useRoute();
const router = useRouter()
const routerTo = (url) => {
  router.push(url)
}

const active = ref('1')
const choosed = ref(false)
const openMenu = ref(true)
const openMenuFn = (value) => {
  openMenu.value = value
}

watchEffect(() => {
  if (route.path === '/buy') {
    active.value = 1
    choosed.value = true
  } else if (route.path === '/about') {
    active.value = 2
    choosed.value = true
  } else if (route.path === '/careers') {
    active.value = 3
    choosed.value = true
  } else if (route.path === '/roadmap') {
    active.value = 4
    choosed.value = true
  } else {
    active.value = 0
    choosed.value = false
  }
})

</script>

<style lang="scss" >

.effect-1 {
  position: absolute;
  width: 542.0496px;
  height: 670.5296px;
  left: -223.0496px;
  top: -273.2704px;
  background: #300DC5;
  opacity: 0.4;
  filter: blur(250px);
  pointer-events: none;
  z-index: 2;
}

#app {
  min-height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.drop-menu {
  display: flex;
  flex-direction: column;
  color: #000207;
  padding: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  // background: linear-gradient(90.64deg, rgba(255, 255, 255, 0.12) 0.4%, rgba(255, 255, 255, 0.12) 98.36%);
  // backdrop-filter: blur(0.17196rem);
  el-dropdown-item {
    cursor: pointer;
  }
}

::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1080px) {
  .effect-1 {
    width: 356.54px;
    height: 441.05px;
    left: -177.23px;
    top: -222.12px;
    transform: translateZ(0);
  }

  .test {
    height: calc(100vh - 100px);
    overflow: hidden;
  }
}
</style>
