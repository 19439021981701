<template>
  <div class="home">
    <div class="effect-1"></div>

    <div class="banner">
      <svg-icon icon-class="maskman" class="svg-img img-text" />
      <!-- <svg-icon icon-class="man" class="img-main" /> -->
      <img class="img-main" src="@/assets/images/image1.png" alt="">
      <img class="img-main-mobile" src="@/assets/images/image1_2.png" alt="">
      <svg-icon icon-class="maskman_2" class="svg-img img-text" />
    </div>
  </div>
</template>

<script setup>

</script>
<style scoped lang="scss">
.banner {
  margin: 90px 0;
  width: 1168px;
  // height: 400px;
  height: 580px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  background: #C81947;
  // height: 100px;
  border-radius: 24px 24px 0 0;
  position: relative;
  z-index: 33;

  .img-main {
    position: absolute;
    // width: 418.67px;
    // height: 500px;
    // height: 687px;
    // width: 705px;
    width: 565px;
    height: 675px;
    left: 50%;
    transform: translateX(-50%) translateY(-60px);
  }

  .img-main-mobile {
    display: none;
  }

  .img-text {
    position: absolute;
    bottom: 0;
    width: 1168px;
    height: 163px;
    // height: 112px;
    padding: 0;
    // width: 1168px;
    left: 50%;
    transform: translateX(-50%);
  }

}

@media screen and (max-width: 1080px) {
  .banner {
    margin: 0;
    margin-top: 150px;

    width: 100%;
    height: 478px;
    border-radius: 0;

    .img-text {
      width: 358px;
      height: 47px;
      bottom: 63px;

    }

    .img-main {
      display: none;
    }

    .img-main-mobile {
      display: block;
      position: absolute;
      width: 389.87px;
      height: 530px;
      left: 50%;
      transform: translateX(-50%) translateY(-115px);

    }

  }
}
</style>