<!--  -->
<template>
    <div class="footer-container">
        <div class="menu">
            <!-- <div class="logo" @click="routerTo('/')">MaskMan</div> -->
            <div class="tab" :class="props.choosed ? 'choosed' : ''">
                <span :class="props.active === 1 ? 'active' : ''" @click="routerTo('buy', 1)">Buy Maskman</span>
                <span :class="props.active === 2 ? 'active' : ''" @click="routerTo('about', 2)">About</span>
                <span :class="props.active === 3 ? 'active' : ''" @click="routerTo('careers', 3)">Careers</span>
                <span :class="props.active === 4 ? 'active' : ''" @click="routerTo('roadmap', 4)">Roadmap</span>
            </div>
            <div class="operation">
                <div class="svg-back" @click="openPage()">
                    <svg-icon icon-class="email" class="svg-img" />
                </div>
                <div class="svg-back" @click="openPage()">
                    <svg-icon icon-class="twitter" class="svg-img" />
                </div>
                <div class="svg-back" @click="openPage()">
                    <svg-icon icon-class="game" class="svg-img" />
                </div>
                <div class="svg-back" @click="openPage()">
                    <svg-icon icon-class="camera" class="svg-img" />
                </div>
            </div>
        </div>
        <div class="company">
            <span>Copyright © Maskman. All Right Reversed</span>
        </div>
    </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'

const emits = defineEmits(["routerTo"])
const routerTo = (url) => {
    emits("routerTo", url)
}
const props = defineProps({
    active: Boolean,
    choosed: String
})
const openPage = (url='https://www.baidu.com') => {
    window.open(url, '_blank')
}


</script>
<style scoped lang="scss">
.footer-container {
    position: relative;
    margin-top: auto;
    background: #0F0E14;
    width: 100%;
    height: 163px;
    // padding: 24px 171.5px 0;
    padding-top: 24px;
    // text-align: center;
    box-sizing: border-box;
    z-index: 2;
    .menu {
        width: 1168px;
        margin-left: auto;
        margin-right: auto;
        // border-bottom: 1px solid #A6AEBB66;
        padding-bottom: 16px;
        display: flex;
        align-items: center;

        .logo {
            font-weight: 700;
            font-size: 32px;
            line-height: 150%;
            margin-right: 213px;
            z-index: 1;
            cursor: pointer;
        }

        .choosed {
            span {
                color: #C0C0C0;
            }
        }

        .tab {
            display: flex;
            align-items: center;
            margin-right: auto;

            span {
                font-family: 'Inter';
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                padding: 4px 10px;
                white-space: nowrap;
                color: #C0C0C0;
                cursor: pointer;
                &:hover {
                    color: #fff;
                }
            }

            .active {
                background: #232122;
                border-radius: 40px;
                color: #fff;
            }

            span+span {
                margin-left: 12px;
            }
        }



        .operation {
            // margin-left: auto;
            display: flex;
            align-items: center;

            .svg-back {
                background: #17161F;
                border-radius: 50%;
                margin-left: 24px;
                height: 40px;
                width: 40px;
                min-width: 26px;
                min-height: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .svg-img {
                height: 16px;
                width: 16px;
                min-width: 12px;
                min-height: 12px;
                cursor: pointer;
                padding: 0;
                color: #fff;

                &:hover {
                    color: #c81947;
                }
            }
        }
    }

    .company {
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #C0C0C0;
        opacity: 0.8;

    }
}

@media screen and (max-width:1080px) {
    .footer-container {
        height: 148.24px;
        padding-top: 19px;

        .logo {
            display: none;
        }

        .menu {
            width: 100%;
            padding-bottom: 0;
            // flex-wrap: wrap;
            justify-content: center;
            flex-direction: column-reverse;

            .tab {
                margin-left: auto;
                margin-right: auto;
                padding: 20px 0;

                span {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 160%;
                    letter-spacing: 0.08em;
                    color: #FFFFFF;
                    padding: 0;
                }

                span+span {
                    margin-left: 32px;
                }

                .active {
                    background: none;
                }
            }

            .operation {
                .svg-back {
                    height: 32px;
                    width: 32px;

                    .svg-img {
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }

        .company {
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
            color: #C0C0C0;
            opacity: 0.7;
        }
    }
}
</style>