import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Buy from "@/views/buy.vue";
import About from "@/views/about.vue";
import Careers from "@/views/careers.vue";
import Roadmap from "@/views/roadmap.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/buy",
    name: "Buy",
    component: Buy,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/careers",
    name: "Careers",
    component: Careers,
  },
  {
    path: "/roadmap",
    name: "Roadmap",
    component: Roadmap,
  },
  {
    path: '/:pathMatch(.*)',
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
