import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/assets/css/reset.css";
import "@fontsource/space-grotesk"; 
import "@fontsource/inter";
// import "element-plus/dist/index.css";
import "./utils/rem.js" //我是在vue.config.js配置了快捷路径，按自己的路径来就好

import "@/assets/css/element-variables.scss"

import './assets/icons/index.js'
import svgIcon from '@/components/SvgIcon.vue'
const app = createApp(App);
app.component('svg-icon', svgIcon)

app.use(store).use(router);
app.mount("#app");
